<template>
  <div class="btn-select-vial">
    <Tippy
      :isInteractive="true"
      trigger="click"
      placement="bottom-start"
      :duration="[200, 67]"
      :isDisabled="isDisabled"
      :popperOptions="popperOptions"
    >
      <template #trigger>
        <Btn
          height="s"
          type="primary"
          icon-left="play_arrow"
          :disabled="isDisabled"
          data-test-id="btn-device-quick-start-autosampler"
        >
          <slot>Single injection</slot>
        </Btn>
      </template>
      <template #default="{ hide, isShow }">
        <GlobalEvents v-if="isShow" @keyup.enter="hide" @keydown.esc="hide" />
        <TrayVials
          v-model="vial"
          :config="trayConfig"
          class="btn-select-vial__tray"
          data-test-id="popup-tray-vials"
        />
      </template>
    </Tippy>
  </div>
</template>

<script>
  import Btn from '@/uikitBase/btns/Btn';
  import Tippy from '@/uikitBase/popups/vueTippy/Tippy';
  import TrayVials from '@/uikitProject/vials/vueTrayVials/TrayVials';
  import testDirective from '@/directives/test';
  import { props, EVENT_SELECT } from '../shared/config.ts';

  export default {
    name: 'DesktopBtnSelectVial',

    directives: { test: testDirective },

    components: {
      TrayVials,
      Tippy,
      Btn,
    },

    props,

    data: () => ({
      vial: '',
    }),

    computed: {
      popperOptions() {
        return {
          modifiers: {
            preventOverflow: {
              boundariesElement: 'window',
              enabled: true,
            },
          },
        };
      },
    },

    watch: {
      async vial(value) {
        if (value) {
          this.$emit(EVENT_SELECT, value);
        }
        await this.$nextTick();
        this.vial = '';
      },
    },
  };
</script>

<style lang="scss" scoped>
  .btn-select-vial {
    &__tray {
      padding: 4px 7px 7px 5px;
      border-radius: $border-radius__md;
    }
  }
</style>
